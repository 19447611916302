.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.App-link {
  color: #61dafb;
}

.mq-f {
  margin: 0em !important;
}

.mq-math-mode * {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-style: normal !important;
  font-size: medium !important;
}

.mq-editable-field{
  padding: 1em !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
